<template>
    <div v-if="customer" class="popup full">
        <!-- NavBar -->
        <n-navbar />
        <div class="popup-container">
            <!-- CustomerInfo -->
            <div class="top-container p-0" style="margin-top: 56px">
                <div>
                    <h4 class="mb-1">{{ customer.name }}</h4>
                    <div class="subtitle font-weight-500 mb-3">
                        <span>現在積分為{{ customer.pointBalance }}分</span>
                    </div>
                </div>
            </div>
            <!-- CustomerQRCode -->
            <div class="bg-image-default border-radius-8 p-3 mb-3">
                <div class="flex-element between">
                    <div class="px-2 text-dark-warm-grey font-17 font-weight-500 mb-2">你的QR碼</div>
                </div>
                <div class="time-list-container mb-3">
                    <div class="text-center qrcode">
                        <qrcode :value="customer.id" :options="{ width: 295 }" tag="img"></qrcode>
                    </div>
                </div>
            </div>
            <!-- SubmitButton -->
            <button class="btn primary-btn flex-center mt-3" @click="logout">
                <span class="font-19">登出</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    async created() {
        await this.$store.dispatch('bindDocument', { col: 'customers', id: localStorage.getItem(`${this.shop.merchantId}-customerId`), target: 'customer' })
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
            customer: (state) => state.customer,
        }),
    },

    methods: {
        logout() {
            localStorage.removeItem(`${this.shop.merchantId}-customerId`)
            this.$store.dispatch('unbindState', { item: 'customer' })
            this.$router.push(`/${this.shop.id}`)
        },
    },
}
</script>
